@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

.wishlist {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

.wishlist.open {
  max-height: 1000px;
}
